<template>
  <div class="h-full">
    <div class="console-dash-menu-container">
      <div class="console-dash-menu">
        <u-btn @click="$router.go(-1)" class="opt-btn"><i class="fa-duotone fa-backward" /> Voltar</u-btn>
      </div>
    </div>

    <div class="suporte-cliente-tarefas cli-task-page" v-if="$route.name === 'console.produtos.produto'">
      <div class="nav">
        <a href="/#/console">Console</a>
        <i class="fa fa-chevron-right" />
        <a href="/#/console/cilentes/lista">Produtos</a>
        <i class="fa fa-chevron-right" />
        <span class="nav-end">Produto ID #{{produto && produto.id}}</span>
      </div>

      <div v-if="!loading && produto" class="case">
        <div class="case-header">
          <div class="id">
            {{produto.nome}}
          </div>
          <div class="actions">
          </div>
        </div>
        <div class="case-tab">
          <div class="tab-header">
            <e-row class="full-width">
              <e-col class="m-b-none">Detalhes do produto</e-col>
              <e-col class="text-right m-b-none flex items-center justify-end">
                <menu-right />
              </e-col>
            </e-row>
          </div>
          <div class="tab-content starefa-page">
            <e-row class="def full-width" mr>
              <e-col>
                <e-row mr>
                  <e-col>
                    <task-detail title="Nome">{{ produto.nome }}</task-detail>
                  </e-col>
                </e-row>
                <e-row mr>
                <e-col>
                  <task-detail title="Descrição Simples">{{ produto.descricaoSimples }}</task-detail>
                </e-col>
            </e-row>
              </e-col>
              <e-col>
                <e-row mr>
                  <e-col>
                    <task-detail title="Critério de Cobrança">
                      {{ criteriosCobranca.CriteriosCobranca[produto.criterioCobranca]?.title }}
                    </task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Tipo de Cobrança">
                      <span style="text-transform: capitalize">{{ produto.unidadeMedidaCobranca }}</span>
                    </task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Forma de Pagamento">
                      {{ formasPagamento.FormasPagamento[produto.formaPagamento]?.title }}
                    </task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Permite Auto Contratação">
                      {{ produto.visibilidade ? 'Sim' : 'Não' }}
                    </task-detail>
                  </e-col>
                </e-row>
                <e-row mr>
                  <e-col>
                    <task-detail title="Taxa de Ativação">
                      R$ {{ produto.taxaInstalacao|moeda }}
                    </task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Valor">
                      R$ {{ produto.valor|moeda2 }} <span>{{ produto.unidadeMedidaCobranca }}</span>
                    </task-detail>
                  </e-col>
                  <e-col>
                    <task-detail>
                      <template v-slot:title>
                        <div class="title">
                          Desconto mensal
                          <i class="fa fa-question-circle"><u-tooltip :offset="[5,5]">Em caso de liberação da contratação em um período superior ao mínimo padrão, pode ser aplicado descontos.</u-tooltip></i>
                        </div>
                      </template>
                      {{ produto.descontoMensal|moeda2 }}%
                    </task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Definições de Preço">
                      <div v-if="!produto.criterioUsos || !produto.criterioUsos.length">
                        Não tem
                      </div>
                      <div class="font-bold" v-else>
                        Sim
                      </div>
                    </task-detail>
                  </e-col>
                </e-row>
              </e-col>
            </e-row>
          </div>
        </div>

        <div class="case-tab">
          <div class="tab-header">
            <e-row class="full-width">
              <e-col class="m-b-none">Observações sobre o produto</e-col>
              <e-col class="text-right m-b-none flex items-center justify-end">
              </e-col>
            </e-row>
          </div>
          <div class="tab-content starefa-page">
            <div style="padding-bottom: 30px">
              <div v-if="produto.descricaoCompleta">{{produto.descricaoCompleta}}</div>
              <div v-else>-</div>
            </div>
          </div>
        </div>

        <!-- -->
      </div>
      <div v-else class="case">
        <sl-loading class="size-18" content="" />
      </div>
    </div>
    <router-view v-else />
  </div>
</template>

<script>

import SlLoading from "components/layout/components/Loading.vue"
import {find} from "@/domain/gerencia/produtos/services"
import ERow from "components/layout/components/Row.vue"
import ECol from "components/layout/components/Col.vue"
import TaskDetail from "components/suporte/cliente/components/include/TaskDetail.vue"
import MenuRight from "components/console/components/produtos/include/MenuRight.vue"
import produtoWindow from "components/console/components/produtos/window/produto"
import * as FormasPagamento from "@/domain/gerencia/produtos/helpers/formaPagamento"
import * as CriteriosCobranca from "@/domain/gerencia/produtos/helpers/criterioCobranca"
import {UTooltip} from "uloc-vue"

export default {
  name: "ConsoleProduto",
  provide: function () {
    return {
      produtoContainer: this,
      appContainer: this,
    }
  },
  mixins: [],
  computed: {
    formasPagamento () {
      return FormasPagamento
    },
    criteriosCobranca () {
      return CriteriosCobranca
    }
  },
  data () {
    return {
      loading: true,
      produto: null
    }
  },
  components: {
    MenuRight,
    TaskDetail,
    ERow,
    ECol,
    SlLoading,
    UTooltip
  },
  beforeCreate() {
    document.body.classList.add('cli-task-body')
    document.body.classList.add('cli-page-body')
  },
  beforeDestroy() {
    document.body.classList.remove('cli-task-body')
    document.body.classList.remove('cli-page-body')
  },
  mounted() {
    const element = document.querySelector('.u-erp-layout-container');
    element.scrollTop = 0;
    this.load()
  },
  watch: {
    '$route.params.id' (v) {
      this.$nextTick(() => {
        this.load()
      })
    }
  },
  methods: {
    load() {
      this.loading = true
      find(this.$route.params.id)
          .then(response => {
            this.produto = response.data
            setTimeout(() => {
              document.title = `#${this.produto.nome} - Produtos - SL`
            }, 2)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    produtoWindow,
    edit () {
      this.produtoWindow(this.produto.id)
    }
  },
  meta: {
    title: 'Produto'
  }
}
</script>

<style lang="stylus">
.cli-page-body {
  .console-dash-menu-level1 {
    display none
  }
  .fin-container {
    background transparent !important
  }
}
</style>
