<template>
  <i class="far fa-ellipsis-h icon-mt">
    <u-tooltip class="min-tooltip" :offset="[5,0]">Ações</u-tooltip>
    <menu-options>
      <ul>
        <menu-options-item @click="edit" close label="Editar" />
        <li class="diviser"></li>
        <menu-options-item @click="remove" close label="Excluir" />
        <li class="diviser"></li>
        <menu-options-item disabled close label="Imprimir" />
        <menu-options-item disabled close label="Exportar XML" />
        <menu-options-item disabled close label="Exportar Word" />
        <menu-options-item disabled close label="Enviar por e-mail" />
      </ul>
    </menu-options>
  </i>
</template>

<script>
import MenuOptions from "components/layout/context-menu/context-window-options"
import MenuOptionsItem from "components/layout/context-menu/context-window-options-item"
import {UTooltip} from "uloc-vue"

export default {
  name: "ProdutoMenuRight",
  inject: ['produtoContainer'],
  computed: {
    produto () {
      return this.produtoContainer.produto
    }
  },
  mixins: [],
  methods: {
    edit () {
      this.produtoContainer.edit()
    },
    remove () {
      this.produtoContainer.remove()
    }
  },
  components: {
    MenuOptions,
    MenuOptionsItem,
    UTooltip
  },
}
</script>
